.editor h2 {
  font-size: 1.625rem;
  line-height: 2.625rem;
  font-weight: bold;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}